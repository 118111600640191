.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-pdf__Page__textContent {
  width: auto !important;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
}

.react-pdf__Page__textContent span {
  font-size: 10px !important;
  line-height: 1;
}

.react-pdf__Page__annotations {
  display: none;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}